<template>
  <div v-cloak>
    <LoadingComponent v-if="!apiLoaded" :text="loadingText"></LoadingComponent>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <div class="header block">
            <div class="header-passport">
              <h1>
                Паспорт региона
              </h1>
                     <span v-if="this.fromCZNMap
                        || ['ROLE_CZN_TERRITORIAL', 'ROLE_CZN_MANAGER', 'ROLE_REGIONAL_AGENCY', 'ROLE_REGIONAL_ADMINISTRATOR']
                           .includes(this.$user.role)" class="subheader">
              {{ userRegion.name }}</span>
              <v-autocomplete
                  v-else
                  style="width: 500px; margin-top: 10px"
                  dense
                  label="Регион"
                  :items="regionList"
                  item-text="name"
                  return-object
                  v-model="userRegion"
                  @input="initialize(); departments = userRegion.departments;"
              ></v-autocomplete>
            </div>

            <div class="passport-buttons">
              <v-dialog
                  v-model="dialog"
                  max-width="800px"
                  persistent>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-btn outlined>
                      документы
                    </v-btn>
                  </div>
                </template>
                <PassportInfoDocsDialog
                    :domain="domain"
                    :regionCode="userRegion.code"
                    :departments="departments"
                    :passport-documents-indicator-id="passportDocumentsIndicatorId"
                    @close="dialog=false; dialogKey++"
                    :key="dialogKey" @edit="dialog=false; dialogUpload=true;"/>
              </v-dialog>
              <v-btn outlined @click="$router.push('/passport/archive')"
                     v-if="$user.role==='ROLE_ADMIN' || $user.role==='ROLE_VNII'">
                архив<br>документов
              </v-btn>
              <v-dialog
                  v-if="$user.role==='ROLE_ADMIN' || $user.role==='ROLE_VNII'"
                  v-model="dialogUpload"
                  max-width="800px"
                  persistent>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-btn outlined>
                      загрузить<br>документ
                    </v-btn>
                  </div>
                </template>
                <PassportInfoDocsUploadDialog
                    :domain="domain"
                    :regionCode="userRegion.code"
                    :departments="departments"
                    :passport-documents-indicator-id="passportDocumentsIndicatorId"
                    @close="dialogUpload=false; dialogKey++"
                    :key="dialogKey"/>
              </v-dialog>

              <div class="download-menu">
                <v-menu top>
                  <template v-slot:activator="{ on }">
                    <div class="download-menu-icon" v-on="on">
                      <svg
                          fill="none"
                          height="24"
                          viewBox="0 0 24 24"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                            d="M8 17V15H16V17H8ZM16 10L12 14L8 10H10.5V7H13.5V10H16ZM5 3H19C20.11 3 21 3.9 21 5V19C21 20.11 20.11 21 19 21H5C3.9 21 3 20.11 3 19V5C3 3.9 3.9 3 5 3ZM5 5V19H19V5H5Z"
                            fill="#0033A0"
                        />
                      </svg>
                    </div>
                  </template>

                  <v-list>
                    <v-list-item @click.prevent="exportXls()">
                      <v-list-item-title>Скачать XLSX</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click.prevent="exportPdf()">
                      <v-list-item-title>Скачать PDF</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
          </div>
          <div class="gmap">
            <div class="map-left">
              <yandex-map :settings="yandexMapSettings"
                          :coords="mapCenter"
                          :zoom="10"
                          :show-all-markers="markers.length > 1 ? true : false"
              >
                <ymap-marker
                    v-for="(m, index) in markers"
                    :key="index"
                    :coords="m"
                    marker-id="index"
                    hint-content=""
                />
              </yandex-map>
            </div>

            <div class="map-right">
              <v-text-field
                  placeholder="Введите название ЦЗН"
                  v-model="depsFilter"
                  @input="$forceUpdate()"
                  filled
                  dense
                  rounded
              ></v-text-field>
              <ul>
                <template v-for="dep in departments">
                  <li :key="dep.id + 'sdfer'" v-if="filterDeps(dep.name)" @input="$forceUpdate()">
                    <router-link
                        :to="`/passportczn/info/${activeConfigOfPassportCZN.id}/${dep.id}`">
                      {{ dep.name }}
                    </router-link>
                  </li>
                </template>
              </ul>
            </div>
          </div>

          <router-link :to="'/clientindex?reg='+this.userRegion.code" style="text-decoration: none">
            <div class="clientindex passportinfo">
              <div class="row top-progress">
                <div class="col-lg-4 col">
                  <div class="green">
                    <div>{{ floor2(iccDashboardData.I) }}<span class="text-caption">/50</span>
                    </div>
                    <p> Индекс<br>клиентоцентричности </p></div>
                </div>
                <div class="col-lg-4 col">
                  <div class="blue">
                    <div>{{ floor2(iccDashboardData.Is) }}<span class="text-caption">/50</span></div>
                    <p> Индекс клиентоцентричности с точки зрения соискателей </p></div>
                </div>
                <div class="col-lg-4 col">
                  <div class="yellow">
                    <div>{{ floor2(iccDashboardData.Ir) }}<span class="text-caption">/50</span></div>
                    <p> Индекс клиентоцентричности с точки зрения работодателей </p></div>
                </div>
              </div>
            </div>
          </router-link>

          <div class="list passport">
            <v-expansion-panels v-model="sectionNumber" multiple>
              <v-expansion-panel
                  v-for="(section, sectionIndex) in sections"
                  :key="sectionIndex"
                  class="list-item-wrap">
                <v-expansion-panel-header>
                  <div class="list-item">
                    <a class="list-item-name">
                      <button class="toggleSwitch">
                        <svg
                            class="closed"
                            fill="none"
                            height="24"
                            viewBox="0 0 24 24"
                            width="24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                              d="M12 14.5L17 9.5L7 9.5L12 14.5Z"
                              fill="#1551D0"
                          />
                        </svg>
                        <svg
                            class="opened"
                            fill="none"
                            height="24"
                            viewBox="0 0 24 24"
                            width="24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                              d="M14.5 12L9.5 7L9.5 17L14.5 12Z"
                              fill="#1551D0"
                          />
                        </svg>
                      </button>
                      <span>{{ sectionIndex + 1 }}. {{ section.name }}</span>
                    </a>
                    <v-progress-circular
                        :rotate="-90"
                        :size="50"
                        :value="getProgress(sectionIndex)"
                        :width="6"
                        color="#0033A0"
                    >
                      {{ getProgress(sectionIndex) }}
                    </v-progress-circular>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <table>
                    <thead>
                    <tr>
                      <th colspan="2">Показатели (данные)</th>
                      <th style="width: 300px;">
                        {{ period ? getPeriodName(period) : 'Текущий период' }}
                      </th>
                      <th>
                        <svg
                            @click="previousPeriod()"
                            style="cursor: pointer"
                            fill="none"
                            height="24"
                            viewBox="0 0 24 24"
                            width="24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                              d="M15.41 16.58L10.83 12L15.41 7.41L14 6L8 12L14 18L15.41 16.58Z"
                              fill="#0033A0"
                          />
                        </svg>
                        <svg
                            @click="nextPeriod()"
                            style="cursor: pointer"
                            fill="none"
                            height="24"
                            viewBox="0 0 24 24"
                            width="24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                              d="M8.58997 16.58L13.17 12L8.58997 7.41L9.99997 6L16 12L9.99997 18L8.58997 16.58Z"
                              fill="#0033A0"
                          />
                        </svg>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="(indicator, indicatorIndex) in section.indicators">
                      <tr :key="userRegion.code + period + sectionIndex + '.' + indicator.id">
                        <td>{{ sectionIndex + 1 }}.{{ indicatorIndex + 1 }}</td>
                        <td>{{ indicator.name }}</td>
                        <td>
                          {{ getValueRepresentation(indicator) }}
                          <br>
                          <div class="additional-info">
                            <div v-if="getRelevanceDate(indicator.id)">
                              {{ getRelevanceDate(indicator.id) | dateFormat }}
                            </div>

                            <div v-for="attachment in getFiles(indicator.id)" :key="attachment.id">
                              <a class="docs-attach" @click.prevent="downloadAttachment(attachment)">{{truncateStr(attachment.name, 30)}}</a>
                            </div>
                          </div>
                        </td>
                        <td>
                          <PassportIndicatorEditButton
                              :domain="domain"
                              :indicator-config="indicator"
                              :region-code="userRegion.code"
                              :min-period="minPeriod"
                              :max-period="maxPeriod"
                              :period-span="periodSpan"
                              :editable="editable"
                              @updated="passportIndicatorUpdated()"
                          />
                        </td>
                      </tr>
                      <tr v-for="(subIndicator, subIndicatorIndex) in indicator.subIndicators"
                          :key="userRegion.code + period + sectionIndex + '.' + indicator.id + '.' + subIndicator.id">
                        <td> {{ sectionIndex + 1 }}.{{ indicatorIndex + 1 }}.{{ subIndicatorIndex + 1 }}</td>
                        <td>{{ subIndicator.name }}</td>
                        <td>
                          {{ getValueRepresentation(subIndicator) }}
                          <br>
                          <div class="additional-info">
                            <div v-if="getRelevanceDate(indicator.id)">
                              {{ getRelevanceDate(indicator.id) | dateFormat }}
                            </div>

                            <div v-for="attachment in getFiles(subIndicator.id)" :key="attachment.id">
                              <a class="docs-attach" @click.prevent="downloadAttachment(attachment)">{{truncateStr(attachment.name, 30)}}</a>
                            </div>
                          </div>
                        </td>
                        <td>
                          <PassportIndicatorEditButton
                              :domain="domain"
                              :indicator-config="subIndicator"
                              :region-code="userRegion.code"
                              :min-period="minPeriod"
                              :max-period="maxPeriod"
                              :period-span="periodSpan"
                              :editable="editable"
                              @updated="passportIndicatorUpdated()"
                          />
                        </td>
                      </tr>
                    </template>
                    </tbody>
                  </table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </div>

        <div
            v-if="errorText != null"
            class="error"
            style="position: fixed; bottom: 0"
        >
          Ошибка: {{ errorText }}
        </div>
      </div>
      <div v-else style="height: 1000px"></div>
    </transition>
  </div>
</template>

<style lang="scss">
@import "../../styles/main.scss";
</style>

<script>
import PassportInfoDocsDialog from "./PassportInfoDocsDialog";
import PassportInfoDocsUploadDialog from "./PassportInfoDocsUploadDialog";
import PassportIndicatorEditButton from "./PassportIndicatorEditButton.vue";
import {yandexMap, ymapMarker} from 'vue-yandex-maps'
import LoadingComponent from "@/components/elements/LoadingComponent";
import {loadRegionalDashboardData} from "@/modules/IccRepository";
import {getActiveDomainConfigs, getDomainConfigById} from "@/modules/api.configs";
import {getRegionIndicatorsByPeriod} from "@/modules/api.indicators";
import {downloadFile} from "@/modules/api.files";
import {
  getPeriodByDate,
  getPeriodName,
  nextPeriodRoundRobin,
  previousPeriod,
  previousPeriodRoundRobin
} from "@/modules/periods";

export default {
  name: "passport",
  props: {},
  components: {
    PassportIndicatorEditButton,
    LoadingComponent,
    PassportInfoDocsDialog,
    PassportInfoDocsUploadDialog,
    yandexMap,
    ymapMarker
  },
  data() {
    return {
      domain: "passport_region",
      domainCzn: "passport_czn",
      passportDocumentsIndicatorId: '06466e55-1774-4bd2-9d83-c787cdf29c21',
      periodSpan: 'YEAR',
      period: null,
      minPeriod: '2018',
      maxPeriod: null,
      configId: null,
      dialog: false,
      dialogArchive: false,
      dialogUpload: false,
      sectionNumber: [],
      values: null,
      sections: null,
      cznsNumber: null,
      apiLoaded: false,
      editable: false,
      userRegion: {},
      regionList: [],
      departments: [],
      errorText: null,
      addresses: [],
      fromCZNMap: false, //чтобы нельзя было выбрать регион и редактировать
      markers: [],
      yandexMapSettings: {
        apiKey: '90a80d24-0d3c-4471-b98d-2acf543bc522', //'2c72f1a3-4eb2-42f7-b8be-5ddb2ef89a81'
        lang: 'ru_RU',
        coordorder: 'latlong',
        enterprise: false,
        version: '2.1',
      },
      mapCenter: [55.738778, 37.629861],
      depsFilter: '',
      dialogKey: 0,
      loadingText: [],
      iccDashboardData: {}
    };
  },
  methods: {
    getPeriodName: getPeriodName,

    nextPeriod() {
      this.period = nextPeriodRoundRobin(this.period, this.minPeriod, this.maxPeriod, this.periodSpan, true)
    },

    previousPeriod() {
      this.period = previousPeriodRoundRobin(this.period, this.minPeriod, this.maxPeriod, this.periodSpan, true)
    },

    getValue(indicatorId, type) {
      return this.values.find(e => e.indicatorId === indicatorId && e.type === type && e.period === this.period)?.value
    },

    getValueRepresentation(indicator) {
      const val = this.getValue(indicator.id, 'VALUE')
      if (val == null){
        return '—'
      }
      if (indicator.dataType === 'OPTIONAL'){
        return indicator.options.find(it => it.id == val)?.value || 'Ошибочное значение'
      }
      return val
    },

    truncateStr(str, n) {
      if (!str) return ''
      if (str.length > n) {
        return str.substring(0, n - 3) + "..." + str.substring(str.length - 6, str.length)
      }
      return str
    },

    getFiles(indicatorId){
      const filesJson = this.getValue(indicatorId, 'FILES')
      try{
        return JSON.parse(filesJson)
      } catch (e){
        return []
      }
    },

    async downloadAttachment(attachment) {
      this.loaderDialog = true
      await downloadFile(attachment.id, attachment.name)
      this.loaderDialog = false
    },

    getRelevanceDate() {
      return null;
    },

    floor2(val) {
      if (!val) val = 0
      return Math.floor(val * 100) / 100
    },

    async loadActiveConfig() {
      let req = await getActiveDomainConfigs(this.domain)
      if (req.ok) {
        let configs = req.payload
        if (configs.length === 0) {
          alert('Ошибка. Нет активной конфигурации паспорта региона')
          return
        } else if (configs.length > 1) {
          alert('Ошибка. Найдено более одной конфигурации паспорта региона')
          return
        }
        this.sections = configs[0].content.sections
      } else {
        alert('Ошибка загрузки данных')
      }
    },

    async loadConfigById() {
      let req = await getDomainConfigById(this.domain, this.configId)
      if (req.ok) {
        let config = req.payload
        this.sections = config.content.sections
      } else {
        alert('Ошибка загрузки данных')
      }
    },

    async loadConfig() {
      if (this.configId == null) {
        await this.loadActiveConfig()
      } else {
        await this.loadConfigById()
      }
    },

    getProgress(sectionIndex) {
      return Math.round(
          (this.getSectionValuesPresent(sectionIndex) /
              this.getSectionValuesCount(sectionIndex, this.period != null)) *
          100
      );
    },

    getSectionValuesCount(sectionIndex, yearly) {
      let section = this.sections[sectionIndex]
      return section.indicators.filter(e => !yearly || e.yearly).length + //количество показателей
          section.indicators.reduce((acc, val) => acc + (val.subIndicators?.filter(e => !yearly || e.yearly).length || 0), 0) // плюс количество подпоказателей
    },

    getSectionValuesPresent(sectionIndex) {
      let section = this.sections[sectionIndex]
      return section.indicators.reduce((acc, indicator) =>
              (this.getValue(indicator.id, 'VALUE') != null ? acc + 1 : acc) +
              (indicator.subIndicators?.reduce((subAcc, subIndicator) =>
                      this.getValue(subIndicator.id, 'VALUE') != null ? subAcc + 1 : subAcc, 0)
                  || 0)
          , 0)
    },

    async loadValues() {
      this.values = []
      let req = await getRegionIndicatorsByPeriod(
          this.domain,
          this.userRegion.code,
          this.period
      )
      if (!req.ok){
        alert('Ошибка получения данных')
        return
      }
      this.values = req.payload
    },

    getCznsNumber() {
      if (['ROLE_CZN_TERRITORIAL', 'ROLE_CZN_MANAGER', 'ROLE_REGIONAL_AGENCY', 'ROLE_REGIONAL_ADMINISTRATOR']
          .includes(this.$user.role)) {
        this.cznsNumber = this.findCznsCount();
      } else {
        this.cznsNumber = this.userRegion.departments.length;
      }
    },

    findCznsCount() {
      let region = this.regionList.filter((region) => {
        return region.code === this.userRegion.code
      });
      return region[0].departments.length;
    },

    forceRerender() {
      this.dialogKey += 1;
    },

    setMapMarkers() {
      for (let department of this.userRegion.departments) {
        if (department.coords) {
          let coords_arr = department.coords.split(',')
          this.markers.push([coords_arr[1], coords_arr[0]])
        }
      }
    },

    async initialize() {
      await this.loadConfig();
      await this.loadValues();
      this.setMapMarkers();
      this.getCznsNumber();
      this.iccDashboardData = await loadRegionalDashboardData(3, this.userRegion.code)
      this.forceRerender();
    },

    async passportIndicatorUpdated(){
      await this.loadValues()
      this.$forceUpdate()
    },

    exportXls() {
      this.export("xlsx");
    },

    exportPdf() {
      this.export("pdf");
    },

    async export(format) {
      this.showLoaderDialog = true;
      let query = {
        headerCells: [
          {
            caption: "Раздел",
            width: 10000,
          },
          {
            caption: "Показатель",
            width: 10000,
          },
          {
            caption: "Год",
            width: 4000,
          },
          {
            caption: "Значение",
            width: 15000,
          },
        ],
        rows: [],
      };

      query.rows.push([
        {cellDataType: "STRING", value: "Общая информация о службе занятости субъекта РФ"},
        {cellDataType: "STRING", value: "Субъект РФ"},
        {cellDataType: "INTEGER", value: ""},
        {cellDataType: "STRING", value: this.userRegion.name},
      ]);

      for (let v of this.values.filter(e => e.type === 'VALUE')) {
        let section = this.getSection(v.indicatorId);
        let indicator = this.getIndicator(section, v.indicatorId);

        query.rows.push([
          {cellDataType: "STRING", value: section.name},
          {cellDataType: "STRING", value: indicator.name},
          {cellDataType: "INTEGER", value: v.year},
          {cellDataType: "STRING", value: v.value},
        ]);
      }

      await this.$downloadApi("ИС_Мониторинга_СЗН_Паспорт." + format.toLowerCase(), "/export/" + format, query, true);
      this.showLoaderDialog = false;
    },

    getSection(indicatorId) {
      for (let s of this.sections) {
        for (let i of s.indicators) {
          if (i.id === indicatorId) {
            return s
          }

          if (i.subIndicators != null) {
            for (let si of i.subIndicators) {
              if (si.id === indicatorId) {
                return s
              }
            }
          }
        }
      }
    },

    getIndicator(section, indicatorId) {
      for (let i of section.indicators) {
        if (i.id === indicatorId) {
          return i
        }

        if (i.subIndicators != null) {
          for (let si of i.subIndicators) {
            if (si.id === indicatorId) {
              return i
            }
          }
        }
      }
    },

    getRegionByCode(regionCode) {
      for (let region of this.regionList) {
        if (region.code === regionCode) {
          return region
        }
      }
    },

    getDepartments(regionCode) {
      for (let region of this.regionList) {
        if (region.code === regionCode) {
          this.departments = region.departments.filter(e=>['territorial_czn', 'manager_czn'].includes(e.type))
        }
      }
    },

    filterDeps(name) {
      return name.toUpperCase().includes(this.depsFilter.toUpperCase())
    },

    async loadActiveConfigOfPassportCZN() {
      let req = await getActiveDomainConfigs(this.domainCzn)
      if (req.ok) {
        this.activeConfigOfPassportCZN = req.payload[0];
      } else {
        this.errorText =
            "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
    },
  },
  watch: {
    period: {
      async handler() {
        await this.loadValues()
        this.$forceUpdate()
      }
    }
  },
  async beforeMount() {
    this.configId = this.$route.params.configId;
    this.regionList = this.$cznDepartmentsByRegionList;
    this.maxPeriod = previousPeriod(getPeriodByDate(this.$serverDateTime, this.periodSpan), this.periodSpan)

    if (['ROLE_CZN_TERRITORIAL', 'ROLE_CZN_MANAGER', 'ROLE_REGIONAL_AGENCY', 'ROLE_REGIONAL_ADMINISTRATOR']
        .includes(this.$user.role)) {
      this.userRegion = this.regionList.find(e => e.code === this.$user.region.code);
    } else { //для админов и ВНИИ Труда
      this.userRegion = this.regionList[0];
    }
    let regionCode = this.$route.params.region,
        index = this.$route.params.sectionNumber;
    if (regionCode != null) { //при прямом переходе с карты СЗН
      this.fromCZNMap = true;
      this.userRegion = this.getRegionByCode(regionCode);
      if (index) {
        this.sectionNumber = index.split("-").map(item => Number(item));
      }
    }
    this.loadingText.push('Загружается карточка паспорта региона')
    await this.loadActiveConfigOfPassportCZN();
    this.loadingText.splice(this.loadingText.length - 1, 1)
    this.loadingText.push('Загружена карточка паспорта региона')

    this.getDepartments(this.userRegion.code);

    this.loadingText.push('Загружаются данные паспорта региона')
    await this.initialize();
    this.loadingText.splice(this.loadingText.length - 1, 1)
    this.loadingText.push('Загружены данные паспорта региона')

    this.editable = !this.fromCZNMap
        && (this.$user.subroles.includes('REGION_PASSPORT_EDITOR') || this.$user.role === 'ROLE_ADMIN' || this.$user.role === 'ROLE_VNII')
        //&& this.config.active;
    this.apiLoaded = true;
  },
};
</script>

<style lang="scss">
@import "../../styles/main.scss";
</style>

<style lang="scss" scoped>
.subheader {
  font-size: 17px;
  color: #1C1C1F;
  font-weight: 600;
}

.additional-info {
  div {
    display: inline-block;
    min-width: 65px;
    font-size: 13px;
    font-weight: 500;
    color: #777779;
  }

  .v-icon {
    display: inline-block;
    font-size: 14px;
    color: #777779;
  }

  .v-icon:nth-child(2) {
    margin-left: 5px;
  }
}
</style>
