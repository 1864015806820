<template>
  <v-dialog
      v-model="dialog"
      max-width="600px"
      persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <a v-bind="attrs"
         v-on="on"
         href="#"
         @click.prevent
      >
        <div class="edit">
          <svg fill="none" height="24" viewBox="0 0 24 24" width="24"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.13 12L19.39 10.74C19.83 10.3 20.39 10.06 21 10V9L15 3H5C3.89 3 3 3.89 3 5V19C3 20.1 3.89 21 5 21H11V19.13L11.13 19H5V5H12V12H18.13ZM14 4.5L19.5 10H14V4.5ZM19.13 13.83L21.17 15.87L15.04 22H13V19.96L19.13 13.83ZM22.85 14.19L21.87 15.17L19.83 13.13L20.81 12.15C21 11.95 21.33 11.95 21.53 12.15L22.85 13.47C23.05 13.67 23.05 14 22.85 14.19Z"
                fill="#0033A0"/>
          </svg>
        </div>
      </a>
    </template>
    <passport-indicator-edit-dialog
        v-if="dialog"
        :domain="domain"
        :indicator-config="indicatorConfig"
        :region-code="regionCode"
        :min-period="minPeriod"
        :max-period="maxPeriod"
        :period-span="periodSpan"
        :editable="editable"
        @close="dialog=false"
        @updated="dialog=false;$emit('updated')"
    ></passport-indicator-edit-dialog>
  </v-dialog>
</template>

<script>
import PassportIndicatorEditDialog from "./PassportIndicatorEditDialog";

export default {
  name: 'PassportIndicatorEditButton',
  components: {PassportIndicatorEditDialog},
  props: [
    'domain',
    'indicatorConfig',
    'regionCode',
    'minPeriod',
    'maxPeriod',
    'periodSpan',
    'editable'
  ],
  data: () => ({
    dialog: false
  }),
  methods: {
    updated() {
      this.dialog = false
      this.$emit('updated')
    }
  },
  async beforeMount() {
  }
}
</script>
