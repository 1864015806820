<template>
  <v-card class="docs">
    <v-card-title>
      <div class="text-h5 text-center">Список документов</div>
    </v-card-title>

    <div class="popup-filter">
      <v-autocomplete
          dense
          v-if="$user.role==='ROLE_ADMIN' || $user.role==='ROLE_VNII'"
          v-model="cznFilter"
          label="Фильтр по ЦЗН"
          :items="departments"
          item-text="name"
          return-object
          @change="$forceUpdate()"
          clearable
      ></v-autocomplete>
    </div>

    <div class="popup-filter">
      <v-autocomplete
          dense
          label="Фильтр по разделам"
          @change="$forceUpdate()"
          clearable
      ></v-autocomplete>
    </div>

    <div class="docs-table">
      <table>
        <tr>
          <th class="doc-title">Документ</th>
          <th class="doc-czn">ЦЗН</th>
          <th class="doc-date">Дата загрузки</th>
        </tr>
        <template v-for="value in filteredValues">
          <tr
              v-for="file in getFilesFromValue(value)"
              :key="'doc_' + file.id">
            <td>
              <a class="docs-attach" @click="downloadFile(file)">
                {{ truncateStr(file.name, 30) }}
              </a>
            </td>
            <td>
              {{ value.cznId == null ? 'Все' : getCznNameById(value.cznId) }}
            </td>
            <td>
              {{ value.createdDateTime | dateFormat }}
            </td>
          </tr>
        </template>
      </table>
    </div>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
          class="btn btn-outline"
          text
          @click="$emit('close')"
      >
        Закрыть
      </v-btn>
      <v-btn
          v-if="$user.role==='ROLE_ADMIN' || $user.role==='ROLE_VNII'"
          class="btn btn-primary"
          @click="$emit('edit')"
      >
        Редактировать
      </v-btn>
    </v-card-actions>
    <loader-dialog :show="loaderDialog" msg="Загрузка файла"></loader-dialog>
  </v-card>
</template>

<script>
import {getRegionIndicatorsByIndicatorId} from "@/modules/api.indicators";
import {downloadFile} from "@/modules/api.files";
import LoaderDialog from "@/components/elements/LoaderDialog.vue";

export default {
  name: 'PassportInfoDocsDialog',
  components: {LoaderDialog},
  props: ['domain', 'regionCode', 'departments', 'passportDocumentsIndicatorId'],

  data: () => ({
    values: [],
    cznFilter: null,
    loaderDialog: false,
  }),
  methods: {
    getFilesFromValue(value) {
      try{
        return JSON.parse(value.value)
      } catch (e){
        return []
      }
    },

    getCznNameById(cznId) {
      const czn = this.departments.filter(d => d.id === cznId)
      if (czn.length) {
        return czn[0].name
      }
      return cznId
    },

    truncateStr(str, n) {
      if (str.length > n) {
        return str.substring(0, n - 3) + "..." + str.substring(str.length - 6, str.length)
      }
      return str
    },

    async downloadFile(file) {
      this.loaderDialog = true
      await downloadFile(file.id, file.name)
      this.loaderDialog = false
    },

    filterCzns(){
      this.values = this.values.filter(v => {
        return v.cznId == null || v.cznId === this.$user.department.id
      })
    }
  },
  computed: {
    filteredValues() {
      return this.values.filter(v => this.cznFilter === null || v.cznId === this.cznFilter.id)
    }
  },
  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    },
  },
  async beforeMount() {
    let req = await getRegionIndicatorsByIndicatorId(
        this.domain,
        this.regionCode,
        this.passportDocumentsIndicatorId
    )
    if (req.ok) {
      this.values = req.payload.filter(v => v.type === 'FILES')
      if (this.$user.role === 'ROLE_CZN_MANAGER' || this.$user.role === 'ROLE_CZN_TERRITORIAL') {
        this.filterCzns()
      }
    } else {
      alert('Ошибка загрузки данных')
    }
  }
}
</script>


