import api from "@/modules/api";

export async function getDomainConfigById(domain, id){
    return api.get(`/config/domain_configs/${domain}/${id}`)
}

export async function getDomainConfigs(domain){
    return api.get(`/config/domain_configs/${domain}`)
}

export async function getActiveDomainConfigs(domain){
    return api.get(`/config/domain_configs/${domain}/active`)
}

export async function saveDomainConfig(domain, config){
    return api.postJson(`/config/domain_configs/${domain}`, config)
}

export async function updateDomainConfig(domain, id, config){
    return api.putJson(`/config/domain_configs/${domain}/${id}`, config)
}

export async function deleteDomainConfig(domain, id){
    return api.delete(`/config/domain_configs/${domain}/${id}`)
}

export async function setDomainConfigStatus(domain, id, active, singleActive){
    return api.postJson(`/config/domain_configs/${domain}/${id}/set_status`, null, {active, singleActive})
}

