<template>
  <v-card class="docs">
    <v-card-title>
      <div class="text-h5 text-center">Редактирование документов</div>
    </v-card-title>

    <div class="docs-table">
      <table>
        <tr>
          <th class="doc-title">Документ</th>
          <th class="doc-czn">Раздел</th>
          <th class="doc-czn">ЦЗН</th>
          <th class="doc-date">Дата загрузки</th>
          <th class="doc-del"></th>
        </tr>
        <template v-for="value in values">
          <tr v-for="file in getFilesFromValue(value)" :key="'doc_' + file.id">
            <td>
              <a class="docs-attach" @click="downloadAttachment(file)">
                {{ truncateStr(file.name, 30) }}
              </a>
            </td>
            <td>
              <v-autocomplete dense></v-autocomplete>
            </td>
            <td>
              <v-autocomplete
                  dense
                  :items="departments"
                  item-text="name"
                  item-value="id"
                  v-model="file.cznId"
                  @change="updateCznId(value, file, $event)"
              ></v-autocomplete>
            </td>
            <td>{{ file.createdDateTime | dateFormat }}</td>
            <td class="doc-del" style="cursor:pointer" @click="deleteAttachment(file, value)">
            </td>
          </tr>
        </template>
      </table>
    </div>
    <div class="doc-add">
      <a href="#" @click.prevent="$refs['fileupload'].click()">Добавить документ</a>
      <input type="file" ref="fileupload" style="display:none" @change="addAttachment($event.target.files[0])">
    </div>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn class="btn btn-outline" text @click="$emit('close')">
        Закрыть
      </v-btn>
      <v-btn class="btn btn-primary" @click="submit()">
        Сохранить
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { getRegionIndicatorsByIndicatorId, saveIndicators } from "@/modules/api.indicators";
import { downloadFile, uploadFile } from "@/modules/api.files";

export default {
  name: 'PassportInfoDocsUploadDialog',
  props: ['domain', 'regionCode', 'departments', 'passportDocumentsIndicatorId'],
  data() {
    return {
      values: [],
    };
  },
  methods: {
    getFilesFromValue(value) {
      try {
        return JSON.parse(value.value);
      } catch (e) {
        return [];
      }
    },

    addFileToValues(file) {
      const existingValue = this.values.find(v => v.cznId === file.cznId);
      if (existingValue) {
        const files = this.getFilesFromValue(existingValue);
        files.push(file);
        existingValue.value = JSON.stringify(files);
      } else {
        this.values.push({
          indicatorId: this.passportDocumentsIndicatorId,
          regionCode: this.regionCode,
          cznId: file.cznId,
          period: null,
          value: JSON.stringify([file]),
          type: 'FILES',
        });
      }
    },

    async addAttachment(file) {
      this.loaderDialog = true;
      const fileInfo = await uploadFile(file);
      const newFile = {
        id: fileInfo.id,
        name: fileInfo.name
      };
      this.addFileToValues(newFile);
      this.loaderDialog = false;
    },

    updateCznId(value, file, cznId) {
      // Удалить файл из старого значения
      let files = this.getFilesFromValue(value);
      files = files.filter(f => f.id !== file.id);
      value.value = JSON.stringify(files);

      // Добавить файл к новому значению с `cznId`
      const newValue = this.values.find(v => v.cznId === cznId);
      if (newValue) {
        const newFiles = this.getFilesFromValue(newValue);
        newFiles.push({ ...file });
        newValue.value = JSON.stringify(newFiles);
      } else {
        this.values.push({
          indicatorId: this.passportDocumentsIndicatorId,
          regionCode: this.regionCode,
          period: null,
          cznId: cznId,
          value: JSON.stringify([{ ...file }]),
          type: 'FILES',
        });
      }

      this.values = [...this.values];
    },

    deleteAttachment(attachment, value) {
      let files = this.getFilesFromValue(value);
      files = files.filter(file => file.id !== attachment.id);
      value.value = JSON.stringify(files);
    },

    async downloadAttachment(file) {
      this.loaderDialog = true;
      await downloadFile(file.id, file.name);
      this.loaderDialog = false;
    },

    async submit() {
      const req = await saveIndicators(this.values);
      if (req.ok) {
        this.$emit('close');
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
    },

    truncateStr(str, n) {
      if (str.length > n) {
        return str.substring(0, n - 3) + "..." + str.substring(str.length - 6, str.length);
      }
      return str;
    },
  },
  filters: {
    dateFormat(date) {
      if (date == null) {
        return '';
      }
      const mdate = new Date(date);
      const options = { timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric' };
      return mdate.toLocaleDateString('ru-RU', options);
    },
  },
  async beforeMount() {
    const req = await getRegionIndicatorsByIndicatorId(
        this.domain,
        this.regionCode,
        this.passportDocumentsIndicatorId
    );
    if (req.ok) {
      this.values = req.payload.filter((v) => v.type === 'FILES');
    } else {
      alert('Ошибка загрузки данных');
    }
  },
};
</script>
