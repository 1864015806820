<template>
  <div class="text-center">
    <v-dialog
      v-model="show"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          <template v-if="msg">{{msg}}</template>
          <template v-else>Подготовка файла...</template>
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'LoaderDialog',
  props: ['show', 'msg'],
  data() {
    return {}
  }
}
</script>

<style scoped>
</style>
